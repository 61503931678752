.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  cursor: pointer;
}

.modal {
  background: rgba(0, 0, 0, 0.5);
}

/* Customizing the react-select */

.react-select-container,
.react-select__value-container {
  min-height: 34px;
}

.react-select__control {
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  min-height: 30px;
}

.react-select-container input {
  padding: 0px !important;
  margin: 0px;
  outline: none !important;
}

.react-select__menu {
  background-color: #ccc !important;
}

.react-select__menu-list {
  background-color: #ececec;
  border-radius: 4px;
}

.react-select__option {
  color: #4d4d4d !important;
}

.react-select__option--is-selected,
.react-select__option--is-focused {
  background-color: #c4c4c4 !important;
  color: #4d4d4d !important;
}

/* ---------------------------- */

/* Customizing the react-ui-icheck */

.form-check > label {
  position: relative;
  display: flex;
}

* {
  -o-transition-property: none !important;
  -moz-transition-property: none !important;
  -ms-transition-property: none !important;
  -webkit-transition-property: none !important;
  transition-property: none !important;
}

.form-check > label > span {
  max-width: 95%;
}

.relationship-filter-wrapper{
  width: 100%;
}

/* ---------------------------- */

.search-bar {
  margin-top: 15px;
}

.select-button {
  margin-top: 15px;
}

@media (max-width: 767px) {
  .select-button {
    margin-left: 2px;
    margin-top: 6px;
  }
}

@media only screen and (min-width: 1200px) {
  .biological-gender__wrapper .form-check {
    width: 33%;
    float: left;
  }

  .family-filter-blood-relatives-radio{
    margin-right: 10;
  }

  .new-patient-button {
    margin-left: -15% !important;
  }

  .search-bar {
    margin-top: 0px;
    justify-content: left;
  }

  .select-button {
    margin-top: 0px;
  }
}

.login-error-message__li {
  display: list-item;
  list-style: none;
  text-align: left;
}

.validation-summary__li {
  display: list-item;
  list-style: disc inside none !important;
  text-align: left;
}

.validation-inline__error-text {
  color: red;
  margin-bottom: 0px;
  font-size: 12px;
}

.dropdown--relative {
  position: relative;
}

.dropdown-menu--reposition {
  left: -95px;
  top: 70px !important;
}

.family-history__input-age {
  width: 60px;
}

.td--nowrap {
  white-space: nowrap;
}

.arrow--animate {
  width: 100px;
  height: 100px;
  background: red;
  -webkit-transition-property: width; /* Safari */
  transition-property: width;
  transition-duration: 5s;
  -webkit-transition-duration: 5s; /* Safari */
  -moz-transition-duration: 5s;
}

.arrow--animate:hover {
  width: 30px;
}

.line-height--40 {
  line-height: 40px;
}

.float--none {
  float: none !important;
}

.list-wrapper {
  display: block;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.list-wrapper-none hr:last-child,
.list-wrapper hr:last-child {
  display: none;
}

.phone-input {
  padding-left: 52px !important;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
}

.intl-tel-input {
  display: block !important;
}

.partner-details-container {
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 10px;
}

.partner-details-container hr:last-child {
  display: none;
}

.family-history-modal{
  overflow-y: auto;
  max-height: 400px;
}

.family-history-modal ul span{
  font-family: 'Montserrat';
  font-size: 14px;
}

.family-history-modal .family-member-list{
  font-family: 'Montserrat';
  font-size: 14px;
  padding-left: 10px;
}

.family-history-modal h4{
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
}

.family-history-modal h4:nth-child(n + 2) {
  padding-top: 20px; /* Different padding for the second div onwards */
}

.family-history-modal .family-history-proband{
  padding-left: 10px;
  font-family: 'Montserrat';
  font-size: 14px;
}

.family-history-modal p{
  padding-left: 10px;
  font-family: 'Montserrat';
  font-size: 14px;
}

@media (min-width: 768px) {
  .modal-dialog-wide {
    width: 950px !important;
    margin: 30px auto;
  }
}

.flex-grid-sidebar .col-right {
  height: inherit;
}

/* ---------------------------------------------------
    NAV PILLS
----------------------------------------------------- */
/* Center Nav Pills */
.center-pills {
  display: flex;
  justify-content: flex-end;
}

/* ---------------------------------------------------
    MAIN - WRAP
----------------------------------------------------- */
.patient-nav {
  /* background: #5d366d; */
  background: #e8e8e8;
  box-shadow: 0px 0px 5px #888;
  position: relative;
}

/* .patient-details-emr{
  font-size: 18px;
  padding: 10px 15px 0px;
  color: #ffffff;
  margin: 0;
  display: inline-block;
  line-height: 1.8
}
.patient-details p {
  font-size: 18px;
  padding: 10px 15px 0px;
  color: #ffffff;
  margin: 0;
  display: inline-block;
  line-height: 1.8;
} */
.patient-details-emr,
.patient-details p {
  font-size: 16px;
  padding: 4px 15px 0;
  color: #6a358a;
  margin: 0;
  display: inline-block;
  line-height: 1.8em;
}
.patient-details a.patient-details--update {
  margin: 0 0 0 15px;
  color: #6a358a;
  font-weight: 600;
  opacity: 100 !important;
  border-left: none;
  padding-left: 20px;
}
.patient-nav .nav-pills > li {
  /* height: 45px; */
  height: 30px;
}
.patient-nav .nav-pills > li > a {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  color: #ffffff;
  vertical-align: middle;
  display: inline-block;
  padding-top: 15px;
  border-radius: 0px;
}
.patient-nav .nav-pills > li.active > a {
  color: #65d1b9 !important;
  padding-top: 15px;
}
.nav--center {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .patient-details--update {
    border-left: none;
    padding-left: 0;
  }
}

/* Patient Family History Sub Menu */
.patient-fam {
  padding: 0px 0 15px;
}

/* ---------------------------------------------------
    Risk Assessment
----------------------------------------------------- */
.risk {
  padding: 10px 30px;
}

.risk-table table th {
  background: #33b2df;
  color: #ffffff;
  border-right: 1px solid #ffffff;
}

.risk-table table tr td {
  font-size: 11px;
  border-top: none;
  border-right: 1px solid #ffffff;
}

.risk-table table {
  background: rgba(51, 178, 223, 0.1);
}

.risk-table table .col-gray--head {
  background: #95a5a6;
  color: #ffffff;
}

.risk-table table .col-gray {
  background: #f2f3f4;
}

/* ---------------------------------------------------
    Search
----------------------------------------------------- */
.search-suggest {
  position: relative;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  width: 25em;
  z-index: 4000;
  margin-left: 10px;
}

.search-suggest li {
  padding: 5px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

.search-suggest li:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}

.search-suggest-hidden {
  top: auto;
  left: -1000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.search-suggest-show {
  /*position the autocomplete items to be the same width as the container:*/
  /* top: 100%; */
  /* left: 4%; */
  /* right: 28%; */
}

.search-results {
  display: flex;
  /* align-items: center;
  justify-content: center; */
}

.no-results-container {
  height: 200px;
  position: relative;
}

.no-results__align {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* ---------------------------------------------------
    Famhis Modal
----------------------------------------------------- */
.famhis-modal-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10; /* This must be at a higher index to the rest of your page content */
  transform: translateZ(0);
  background-color: rgba(0, 0, 0, 0.15);
}

.famhis-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 6px;
}

@media screen and (min-width: 31.25em) {
  .famhis-modal {
    left: 50%;
    top: 50%;
    height: 35em;
    transform: translate(-50%, -50%);
    max-width: 45em;
    max-height: calc(100% - 1em);
  }
}

.famhis-modal-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  line-height: 1;
  background: transparent;
  border: 0;
  color: #9a9a9a;
  box-shadow: 0;
  cursor: pointer;
  margin: 4px;
}

.famhis-modal-close-icon {
  width: 2.5em;
  height: 2.5em;
  fill: transparent;
  stroke: #9a9a9a;
  color: #9a9a9a;
  stroke-linecap: round;
  stroke-width: 4;
}

.famhis-modal-header {
  height: 5em;
  background-color: #fff;
  border-bottom: 1px solid #000000;
  border-bottom-color: #9a9a9a;
}

.famhis-modal-header-text {
  display: inline-block;
  padding: 0.8em 0 0 0.2em;
  font-size: 1.5em;
  color: #6a358a;
}

.famhis-modal-body {
  height: 25em;
  padding: 1em 0.5em;
  background-color: inherit;
  overflow-x: hidden;
  overflow-y: auto;
}

.famhis-modal-footer {
  height: 5em;
  border-top: 1px solid #000000;
  background-color: inherit;
  text-align: right;
  padding: 0.5em 0.5em 0 0;
}

.famhis-modal-footer-text {
  display: inline-block;
  padding: 1em 0.5em;
  font-size: 1em;
}

.utility-hide-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.utility-lock-scrolling {
  overflow: hidden !important;
}

.utility-element-focus:focus {
  border: 1px solid #008b8b;
}

.utility-warning-box {
  border: 1px solid #b30000;
  border-radius: 4px;
  background-color: #ff0000;
  color: #000000;
  font-size: 1.2em;
  font-weight: 400;
  opacity: 0.7;
  padding: 10px 5px;
  margin-bottom: 10px;
}

.utility-success-box {
  border: 1px solid #1cb01c;
  border-radius: 4px;
  background-color: #90ee90;
  color: #000000;
  font-size: 1.2em;
  font-weight: 400;
  opacity: 0.7;
  padding: 10px 5px;
  margin-bottom: 10px;
}

/* ---------------------------------------------------
    Btn Link
----------------------------------------------------- */
.btn-link {
  margin: 0;
  padding: 0;
  border: 0;

  background: transparent;
  font-size: inherit;
  height: auto;
}

.btn-link:hover {
  text-decoration: underline;
  color: red;
}

.btn-block-container {
  width: 20em;
  margin-left: auto;
  margin-right: auto;
}

.btn-light-outline {
  font-size: 11px;
  letter-spacing: 0.5px;
  padding: 10px 13px;
  color: #747474;
  background: transparent;
  border: 2px solid #e8e8e8;
  border-radius: 100px;
}

.btn-light-outline:hover {
  background: #e8e8e8;
  border: 2px solid #e8e8e8;
  color: #747474;
}

.bg-transparent {
  background-color: transparent;
}
/* ---------------------------------------------------
    Patient Data Tabs
----------------------------------------------------- */
.patient-tab-btn {
  text-decoration: none;
}

.nav > li > button {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav-pills-unstyled > li.active > button,
.nav-pills-unstyled > li.active > button:focus,
.nav-pills-unstyled > li.active > button:hover {
  border-radius: 0px !important;
}

.patient-sidetab .nav-pills > li button {
  margin: 0;
  border: none;
}

.patient-sidetab .nav-pills > li > button,
.patient-sidetab .nav-pills > li > button:hover,
.patient-sidetab .nav-pills > li > button:focus {
  font-weight: 600;
  font-size: 13px;
  color: #999999;
  text-transform: uppercase;
}

.patient-sidetab .nav-pills > li.active > button,
.patient-sidetab .nav-pills > li.active > button:hover,
.patient-sidetab .nav-pills > li.active > button:focus {
  color: #6a358a;
  background: none;
}

.nav-pills-unstyled > li > button,
.nav-pills-unstyled > li > button:focus,
.nav-pills-unstyled > li > button:hover {
  background: none;
  border-radius: 0px !important;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover,
.btn-group > .btn:focus,
.btn-group-vertical > .btn:focus,
.btn-group > .btn:active,
.btn-group-vertical > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn.active {
  background-color: #e8e8e8;
  color: #747474;
}

.btn-group .btn {
  margin-right: 0;
}

.btn:active,
.btn.active {
  box-shadow: none !important;
}

.patient-nav .nav-pills > li > button.btn-link {
  border: 0;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  background: transparent;
  text-decoration: none;
  height: auto;
  line-height: 16px;
}
/* .patient-nav .nav-pills > li > button{   */
/* font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  color: #999;
  vertical-align: middle;
  display: inline-block;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 0;
  margin-right: 40px;
  height: 50px;
} */
.patient-nav .nav-pills > li > button,
.patient-nav .nav-pills > li > button:hover,
.patient-nav .nav-pills > li > button:focus {
  color: #fff;
  background: none;
}
.patient-nav .nav-pills > li > button:hover,
.patient-nav .nav-pills > li > button:focus {
  color: #6a358a;
  background: none;
}
.patient-nav .nav-pills > li > button {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  color: #999;
  vertical-align: middle;
  display: inline-block;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 0;
  margin-right: 40px;
  height: 50px;
}
.patient-nav .nav-pills > li.active > button,
.patient-nav .nav-pills > li.active > button:hover,
.patient-nav .nav-pills > li.active > button:focus {
  border-bottom: 4px solid #6a358a;
}

.patient-nav .nav-pills > li.active > button {
  /* color: #65d1b9 !important; */
  /* padding-top: 11px; */
  /* padding-bottom: 3px; */
  /* margin-top: 2px; */
  color: #6a358a !important;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 4px;
}

.patient-fam ul.nav li button.btn-link {
  border: 0;
  background: transparent;
  padding: 6px 20px 9px 0px;
  text-decoration: none;
  height: auto;
}
.patient-fam ul.nav li button {
  color: #6a358a;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0;
  vertical-align: middle;
  display: table-cell;
  margin-top: 0;
  margin-bottom: 0;
  padding: 6px 20px;
}
.patient-fam ul.nav li button:hover,
.patient-fam ul.nav li button:focus {
  background: none;
  opacity: 0.8;
}
.patient-fam ul.nav li.active button {
  background: none;
}
.patient-fam ul.nav li button i {
  color: #6a358a;
}
.patient-fam ul.nav li a {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0;
  vertical-align: middle;
  display: table-cell;
  margin-top: 0;
  margin-bottom: 0;
  color: #6a358a;
  padding-bottom: 3px;
  background-color: initial;
}
.patient-fam ul.nav li a i {
  /* color: #fff; */
  color: #6a358a;
}
.notification-badge[data-count]::after {
  /* top: 16%; */
  top: 5%;
}
.patient-fam ul.nav li a.dropdown-toggle {
  padding-right: 20px;
}

.nav-tabs > li > button.btn-link {
  border: 0;
  background: transparent;
  text-decoration: none;
  height: auto;
}
.nav-tabs > li > button.btn-link:hover {
  color: #6a358a;
}
.nav-tabs > li > button:hover {
  /*border-color: #eee #eee #ddd;
	background-color: #ddd;*/
  color: #eee;
}
.nav-tabs > li.active > button,
.nav-tabs > li.active > button:hover,
.nav-tabs > li.active > button:focus {
  color: #6a358a;
  cursor: default;
  background-color: #fff;
  border: 1px solid;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  border-color: #ddd #ddd transparent;
}
.nav-tabs > li > button {
  margin-top: 0;
  color: #999;
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  font-weight: 600;
}
.nav-tabs > li > button {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}
.nav > li > button {
  position: relative;
  display: block;
  padding: 10px 15px;
}

/* .modal-dialog {
  margin-top: 100px
}

.modal {
  z-index: 99999;
} */

.table-filter {
  font-size: 11px;
  padding: 8px 8px;
  display: grid;
}
.table-filter .pull-right {
  min-width: 350px;
}
.table-filter .form-search {
  margin-right: 5px;
}
.table-filter .form-control {
  width: 190px !important;
}
.table-filter select.input-sm {
  width: 65px !important;
  height: 35px !important;
  margin-left: 4px;
  margin-right: 4px;
}

.table-footer .pagination {
  margin: 0 0 0 0;
}
.table-footer .pagination a {
  margin-right: 0;
}
.table-footer .pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background: #65d1b9;
  border-color: #65d1b9;
}
.table-footer .pagination > li > a,
.pagination > li > span {
  color: #777;
}

.table tr > td.middle {
  vertical-align: middle;
}

.table-filter .btn-default {
  width: 60px !important;
}
.table-filter .btn {
  margin-bottom: 5px;
}
.btn-xs,
.btn-group-xs > .btn {
  padding: 6px 8px !important;
  font-size: 11px;
  line-height: 1.5; /* border-radius: 3px;border-top-right-radius: 3px; border-bottom-right-radius: 3px; */
}
.btn-group .btn {
  margin-right: 0;
}
.btn-default {
  border-color: #ccc !important;
  color: #747474;
}
.btn {
  font-weight: 600;
  text-transform: uppercase;
}

.margin-right-one {
  margin-right: 1% !important;
}

.checkbox.checkbox-group {
  display: inline;
}

.radio.radio-group > label {
  display: inline;
}
.app-loading-wraper {
  margin: 100px auto;
  width: 100px;
  height: 100px;
  display: block;
  border: 1px solid #efefef;
  background-color: rgb(247, 247, 247);
  padding: 10px;
  border-radius: 5px;
}
.delete-actions{
  background-color: #fafafa;
  white-space: nowrap;
  position: sticky;
  right: 10px;
}

.delete-custom{
  font-size: 14px;
  color: #BBBBBB;
}

.delete-search {
  position: relative;
  right: 85px;
  top: 10px;
}

.delete-search-hidden {
  position: relative;
  right: 85px;
  top: 10px;
  visibility: hidden;
}

::-ms-reveal {
  display: none;
}

.search-suggest-show i {
  position: sticky;
  left: 90%;
  top: 0px;
}

.sticky-hdr thead th {
  position: sticky;
  right: 0px;
  left: 0px;
  top: 50px;
  background: #fafafa;
  border-bottom: 2px solid #ddd;
  z-index: 1;
}

.sticky-hdr {
  border-collapse: separate;
}

.table-custom .table-indent-0-5 {
  margin-left: 10px;
}

.table-custom .table-indent-2-5 {
  margin-left: 50px;
}

.table-custom .table-indent-3-5 {
  margin-left: 70px;
}

.table-custom .table-indent-5-5 {
  margin-left: 110px;
}

.table-custom .table-indent-6 {
  margin-left: 120px;
}

.custom-row-header i.fa {
  color: #626262;
}

table.dataTable tbody tr.custom-row-header {
  background: #f3f3f3;
  color: #626262;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

/* Tooltip container */
.btn-tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.btn-tooltip .tooltiptext {
  visibility: hidden;
  min-width: 120px;
  background-color: black;
  color: #fff;
  font-size: 0.9em;
  text-align: center;
  padding: 6px 6px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 0px;
  left: 105%;
  z-index: 1;
  opacity: 0.8;
}

/* Show the tooltip text when you mouse over the tooltip container */
.btn-tooltip:hover .tooltiptext {
  visibility: visible;
}

.btn-tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

/* Override Drodpwn select */
.Dropdown-menu {
  max-height: 350px !important;
}
.Dropdown-placeholder {
  min-height: 23px;
}
.Dropdown-option {
  min-height: 41px;
}
.Dropdown-control {
  border: 1px solid #cccccc !important;
  background: #ffffff !important;
  position: relative !important;
  border-radius: 4px !important;
  padding: 5px 52px 5px 10px !important;
}
.Dropdown-option {
  padding: 8px;
  padding-left: 25px !important;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #eee;
  color: #666;
  font-size: 13px;
}
.gene-input-height{
  min-height: 34px !important;
}
.Dropdown-option:hover {
  background: #f0f0f0 !important;
  color: #444;
}
.Dropdown-option.is-selected {
  background: #d0d0d0 !important;
  color: #444 !important;
}
/* End override*/

.alert-transparent {
  background: transparent;
  padding: 0px;
  border: none;
}

.checkbox.checkbox-group.checkbox-yes-no {
  margin-right: 30px;
}

@media (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}

.text-nowrap {
  white-space: nowrap!important;
}
.pt-5 {
  padding-top: 3rem!important;
}
.p-4 {
  padding: 1.5rem!important;
}
.align-items-center {
  align-items: center!important;
}
.d-flex {
  display: flex!important;
}
.overflow-auto {
  overflow: auto!important;
}
.p-0 {
  padding: 0!important;
}
.form-check-inline {
  display: inline-block;
  margin-right: 3rem;
}
.form-check {
  margin-bottom: 0.125rem;
  min-height: 1.5rem;
}

.form-check-flex {
  display: flex;
  flex-direction: row;
}

.radio.radio-group.radio-yes-no > label {
  margin-right: 30px;
}

.check-box-positioning {
  margin-right: 5px;
}

/* ---------------------------------------------------
  Navigation
----------------------------------------------------- */
.dropdown-button-link {
  border: 0;
  text-decoration: none;
}

@media (max-width: 992px) {
  .dropdown-button-link {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    line-height: 20px !important;
  }
}

.navbar-default .navbar-nav > li > button {
  color: #373737;
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  margin-top: -7px;
  text-transform: uppercase;
  background: none;
}

@media (min-width: 992px) {
  .navbar-brand {
    padding: 0px 30px;
    height: 60px;
    line-height: 60px;
    max-width: 90%;
    padding-top: 5px;
  }
}

.nav > li > button {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.navbar-nav > li > button {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .navbar-nav > li > button {
    padding-top: 16.5px;
    padding-bottom: 16.5px;
    line-height: 26.5px;
  }
}

.dropdown-menu > li > button {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  width: 100%;
  text-align: left;
}

.dropdown-menu > li > button:hover,
.dropdown-menu > li > button:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
.dropdown-menu > .active > button,
.dropdown-menu > .active > button:hover,
.dropdown-menu > .active > button:focus {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0;
}
.dropdown-menu > .disabled > button,
.dropdown-menu > .disabled > button:hover,
.dropdown-menu > .disabled > button:focus {
  color: #777;
}
.dropdown-menu > .disabled > button:hover,
.dropdown-menu > .disabled > button:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

.dropdown-menu {
  min-width: 120px;
}

.search-form {
  opacity: 1;
  visibility: visible;
}

#top-search .search-form {
  top: 0 !important;
  background-color: transparent;
  border: 0;
  box-shadow: 0 0 transparent;
  margin-top: -7px;
}

#top-search {
  padding-right: 0px;
}

#top-search i {
  margin: 0 0px 0 8px;
}

#top-search .search-form .search-input {
  border-radius: 100px !important;
  letter-spacing: 0;
  padding: 8px 20px;
  color: #555;
}
.survey-search-bar{
  width: 200px;
}
#survey-search{
  margin-top: 4%;
  width: 100%;
  align-items: center;
}

#surveyTable {
  margin: 14px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
#questionsTable{
  padding-top: 20px;
  width: 90%;
  max-width: 90%;
  margin: 10px;
}

#top-search .close-search {
  color: #373737;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 998;
  cursor: pointer;
  top: 17px;
}

button.search-btn-link {
  border: 0;
  background: transparent;
  text-decoration: none;
}
.close-search {
  color: #373737;
  position: absolute;
  right: 40px;
  top: 20px;
  z-index: 998;
  cursor: pointer;
}

.panel-col .panel-summary.width--full,
.panel-col-gene .panel-summary.width--full {
  width: 100% !important;
  height: inherit !important;
}

.text--uppercase {
  text-transform: uppercase !important;
}

.btn-gray-l {
  color: #4b4a4a !important;
  background-color: #e8e8e8 !important;
  border-radius: 100px;
}

.btn-gray-l:hover {
  background-color: #d6d6d6 !important;
  border-radius: 100px;
}

.btn-spacer {
  padding-bottom: 3px;
}

/*
| - - - - - - - - - - - - - - - - -
| QUERY PAGE
*/
.column-success {
  text-transform: none;
  font-style: normal;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: green;
  padding-left: 36px;
}

.column-save {
  float: right;
  font-weight: bold;
  height: fit-content;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.column-save:hover,
.column-save:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=70);
  opacity: 0.9;
}


.edit-column-label {
  max-width: 180px;
  word-wrap: break-word;
}
.popover-query {
  margin-top: 42px !important;
}

.popover-custom .popover-title {
  margin: 0px;
}

.popover-query__arrow {
  left: 30px !important;
}

.popover-query-wrapper {
  display: inline-block;
  position: relative;
}

.padding--none {
  padding: 0px !important;
}

.table-filter .btn-default.btn-default__width--inherit {
  width: inherit !important;
}

.table-filter .pull-right.min-width--inherit {
  min-width: inherit !important;
}

.padding-left--15 {
  padding-left: 15px;
}
/* END QUERY PAGE*/

.activity-indicator-wrapper {
  text-align: center;
  padding: 20px;
}

.new-patient__activity-indicator-wrapper,
.activity-indicator-wrapper__float--right {
  float: right;
  margin: 35px 15px 0;
}

.activity-indicator-wrapper__modal {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  /* display: none; */
}

.activity-Loader {
  height: 80px;
  width: 80px;
}

.activity-indicator-wrapper__modal__spinner-wrapper {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -45px;
  margin-top: -45px;

  border-radius: 7px;
  height: 90px;
  width: 90px;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #ccc;

  -webkit-box-shadow: 1px 1px 8px -3px rgba(128, 125, 128, 1);
  -moz-box-shadow: 1px 1px 8px -3px rgba(128, 125, 128, 1);
  box-shadow: 1px 1px 8px -3px rgba(128, 125, 128, 1);
}

.btn-dark.disabled {
  background: #414661;
  color: #ffffff;
}

.activity-indicator-wrapper__modal__spinner-pedigree {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -45px;
  margin-top: -45px;

  border-radius: 7px;
  height: 90px;
  width: 90px;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #ccc;

  -webkit-box-shadow: 1px 1px 8px -3px rgba(128, 125, 128, 1);
  -moz-box-shadow: 1px 1px 8px -3px rgba(128, 125, 128, 1);
  box-shadow: 1px 1px 8px -3px rgba(128, 125, 128, 1);
}
.btn-dark.disabled i {
  color: #ffffff;
}
.table-row__error td {
  background-color: red;
}

.custom-react-select__input input {
  padding: 0px !important;
  margin: 0px;
}

.custom-react-select__menu {
  background: #f8f8f8 !important;
}

.custom-react-select__group-heading {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-weight: bold !important;
  font-size: 12px !important;
  padding-left: 10px;
  cursor: default;
  color: #444 !important;
  text-transform: inherit !important;
}
.custom-react-select__option {
  display: block;
  padding-left: 25px !important;
  color: #666 !important;
}

.custom-react-select__option--is-selected {
  background: #d0d0d0 !important;
  color: #444 !important;
}

.custom-react-select__option--is-focused {
  background: #efefef !important;
  color: #444 !important;
}
.custom-react-select__indicator{
  padding: 6px !important;
}
.modal-twin__modal-body {
  padding: 0px;
}

.modal-twin__container {
  overflow-y: scroll;
  overflow-x: hidden;
  /* max-height: 400px; */
  height: 75vh;
  padding: 30px;
}

.modal-body__with-error .alert-danger {
  padding: 10px;
  margin-bottom: 0px;
}

.modal-twin__row-error label {
  color: red;
}
.modal-twin__row-error input,
.modal-twin__row-error .react-select__control {
  background-color: #f2dfde;
}

.modal-twin__container .form-control {
  height: 38px;
}

.modal-error-wrapper {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal-error-wrapper__content {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -200px;
  margin-top: -60px;

  border-radius: 7px;
  height: 120px;
  width: 400px;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #ccc;

  -webkit-box-shadow: 1px 1px 8px -3px rgba(128, 125, 128, 1);
  -moz-box-shadow: 1px 1px 8px -3px rgba(128, 125, 128, 1);
  box-shadow: 1px 1px 8px -3px rgba(128, 125, 128, 1);
}

.modal-error-wrapper__content--error-text {
  color: #a94442;
  text-align: center;
  text-transform: none;
  font-size: 18px;
}
#unsureChoice {
  text-decoration: none;
  padding-left: 2px;
  font-size: 12;
  color: #444444;
  font-weight: normal;
  line-height: 1;
  text-align: center;
}

.m-gene-links {
  margin: 0;
}

.m-gene-links a {
  color: #626262;
}

.m-gene-links a::before {
  content: "\f0c1";
  font-family: "FontAwesome";
  color: #66d1b9;
  margin-right: 8px;
}

.m-section {
  padding: 10px 0;
}

.panel-group {
  margin: 0;
}

.panel-group .panel {
  border: none;
}

.m-panel {
  border-radius: 4px;
}

.m-panel > .panel {
  margin-bottom: 10px !important;
  border: 1px solid #ddd !important;
}

.m-panel .panel-title {
  font-weight: 600;
  text-transform: none;
  letter-spacing: normal;
  font-size: 13px;
  background: none;
  border: none;
  padding: 0px;
}

.m-panel a[aria-expanded="true"] {
  background: none;
  color: #444444;
}

.panel-summary h4 {
  width: 85%;
}

.notifs-wrap--override {
  z-index: 9999;
  margin-top: 50px;
  height: calc(100% - 50px);
}

.notifs-wrap--show {
  transition: 1s;
  right: 0px;
}

.patient-fam--padding {
  padding: 0px 0 15px !important;
}

.search-results-searchBar {
  width: 22em;
  border-radius: 100px !important;
  border: 2px solid #dcdcdc;
  font-size: 16px !important;
  text-transform: none !important;
  padding: 8px 20px !important;
  margin-bottom: 0;
  min-width: 300px;
}

/* clears the 'X' from Internet Explorer */
.input-home-search::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.input-home-search::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
.input-home-search::-webkit-search-decoration,
.input-home-search::-webkit-search-cancel-button,
.input-home-search::-webkit-search-results-button,
.input-home-search::-webkit-search-results-decoration {
  display: none;
}

.badge-success {
  background-color: #66d1ba;
}

.badge-success span {
  color: #000;
  font-weight: bold !important;
  opacity: 1 !important;
}

@media (max-width: 1024px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }

  .navbar-right {
    height: 30px;
  }
}

@media (max-width: 991px) {
  .navbar-nav > li > .dropdown-menu {
    top: 45px !important;
    right: 0px;
  }

  .navbar-nav > li > .dropdown-menu li a i {
    top: 10px;
  }

  .navbar-right .dropdown-menu {
    right: 75px;
    left: auto;
  }

  .profile-dropdown {
    height: 30px;
    width: 50px;
  }

  .dropdown-menu {
    padding: 0px 0px !important;
    position: absolute;
  }

  .dropdown-menu li a {
    padding: 0px 15px !important;
  }
}

@media (min-width: 768px) {
  .patient-sidetab {
    margin-top: 0px;
  }
}

.disease-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0px 0px;
}

.member-info-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0px 0px;
}

.member-info-annex-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0px 0px;
}

@media screen and (min-width: 31.25em) {
  .disease-modal {
    left: 50%;
    top: 30%;
    height: 55em;
    max-width: 100em;
    transform: translate(-50%, -30%) !important;
    max-height: calc(100% - 1em);
    overflow-y: auto;
    margin: 0px 0px;
  }

  .member-info-modal {
    left: 50%;
    top: 30%;
    max-width: 55em;
    transform: translate(-50%, -30%) !important;
    max-height: calc(100% - 1em);
    overflow-y: auto;
    margin: 0px 0px;
  }

  .member-info-annex-modal {
    left: 50%;
    top: 5%;
    height: 40em;
    max-width: 30em;
    transform: translate(-50%, -5%) !important;
    max-height: calc(100% - 1em);
    overflow-y: auto;
    margin: 0px 0px;
  }
}

.patient-details p span {
  line-height: 16px;
}

.patient-fam ul.nav {
  background: #e8e8e8;
}
.patient-fam ul.nav li {
  height: 38px;
  display: table;
  margin-top: 0;
  margin-bottom: 0;
}

.sidebar-collapsible .panel {
  background: #f3f3f3;
  max-width: 100%;
  width: 100%;
  padding: 0px 0 30px;
}

.sidebar-collapsible .panel-heading .panel-title {
  background: #e8e8e8;
  color: #6a358a;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
  padding: 6px 18px 7px;
}

.patient-maintab {
  padding: 30px 30px;
}

.patient-sidetab {
  background: #f1f1f1;
  padding: 30px 15px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

input,
select,
textarea {
  padding: 8px !important;
  text-transform: none;
}

.btn-group-xs > .btn,
.btn-xs {
  padding: 6px 8px !important;
  font-size: 11px;
}

.famhis-home-search {
  border-radius: 100px !important;
  border: 2px solid #dcdcdc;
  font-size: 16px !important;
  text-transform: none !important;
  padding: 8px 20px !important;
  margin-top: 8px;
  margin-bottom: 0;
}

.risk-assessment-left-panel {
  width: 100%;
  height: auto;
  float: left;
}

.risk-assessment-right-panel {
  width: 100%;
  height: auto;
  float: left;
  margin-left: 0px;
  margin-bottom: 20px;
}

@media screen and (min-width: 75em) {
  .risk-assessment-left-panel {
    width: 30%;
  }

  .risk-assessment-right-panel {
    width: 67%;
    margin-left: 3%;
  }
}

@media screen and (max-width: 48em) {
  .famhis-radio-inline {
    padding-top: 0px !important;
  }
}

.search-results-search-icon {
  right: 45px;
  top: 16px;
}

.query-search-button-input {
  grid-template-columns: auto auto auto auto;
  display: grid;
  align-items: center;
}

.flex-right {
  display: flex;
  justify-content: right;
}

.search-button {
  position: absolute;
  right: 815px;
  top: 8px;
}

.search-criteria-area {
  margin-top: 8px;

}

@media screen and (max-width: 1198px) {
  .query-search-button-input {
    grid-template-columns: 1fr 2fr;
    display: grid;
    align-items: center;
  }

  .search-criteria-area {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
  }

  .flex-right {
    display: flex;
    justify-content: left;
  }
}

@media screen and (max-width: 991px) {

  .save-report-button {
    margin-top: 15px
  }

  .new-patient-button {
    margin-top: 5px !important;
  }

  .query-search-button-input {
    grid-template-columns: 1fr 1fr;
    display: grid;
    align-items: center;
  }

  .m-top-two {
    margin-top: 2%;
  }

  .m-top-four {
    margin-top: 4%;
  }

  .search-criteria-area {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
  }

  .center-filter-button {
    justify-content: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
  }
}
@media screen and (max-width: 768px) {
  .query-search-button-input {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 15px;
    margin-top: 7px;
  }
}

.twins-modal {
  margin-left: 77px;
}

.twin-select-column {
  position: relative;
  top: 29px;
}

.twins-slider {
  margin-top: 35px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #top-search {
    display: block;
  }

  #top-search .search-form {
    /* margin-top: -3px; */
    /* margin-top: -7px; */
  }

  #top-search .close-search {
    top: 5px;
  }
}

.full-height {
  height: 100vh !important;
}

.margin-nine-top {
  margin-top: 9% !important;
}

@media print {
  .patient-nav {
    display: none;
  }

  .patient-fam {
    display: none;
  }

  .patient-sidetab {
    display: none;
  }

  .col-md-10 {
    left: 0 !important;
  }
  
  .slider:before {
    background-color: white !important;
   }
   ::-webkit-scrollbar-thumb {
    background: rgba(210, 210, 210, 0.8) !important;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;
   }
   ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
   }
   .slider {
    background-color: rgba(210, 210, 210, 1) !important;
   }
   input:checked + .slider {
    background-color: #66d1ba !important;
    box-shadow: none !important;
   }
}

.account-warning {
  display: block;
  float: right;
  text-align: center;
  margin-top: 5px;
}

.validation-warning {
  display: block;
  float: center;
  text-align: center;
  margin-top: 5px;
}

.validation-warning-age-member-info {
  display: block;
  float: center;
  text-align: center;
  margin-top: 5px;
}

.notification-badge[data-count]:after {
  position: absolute;
  right: 11%;
  top: 5%;
  content: attr(data-count);
  font-size: 60%;
  padding: 0.6em;
  border-radius: 999px;
  line-height: 0.75em;
  color: white;
  background: #66d1ba;
  text-align: center;
  min-width: 2em;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}
@media (max-width: 991px) {
  .notification-badge[data-count]:after {
    position: absolute;
    right: -26%;
    top: 0;
    content: attr(data-count);
    font-size: 60%;
    padding: 0.6em;
    border-radius: 999px;
    line-height: 0.75em;
    color: white;
    background: #66d1ba;
    text-align: center;
    min-width: 2em;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
  }
}

.company-name {
  margin-top: 15px;
  line-height: inherit !important;
  text-transform: none;
  color: #555;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
}

/* Tooltip container */
.i-tooltip-right, .i-tooltip-left {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.i-tooltip-right .i-tooltiptext-right {
  visibility: hidden;
  min-width: 200px;
  background-color: #fff;
  font-size: 0.9em;
  text-align: center;
  padding: 6px 6px;
  border-radius: 6px;
  border: 1px solid lightgray;
  line-height: 1.4;

  /* Position the tooltip text */
  position: absolute;
  top: -28px;
  left: 140%;
  z-index: 1;
}

.i-tooltip-left .i-tooltiptext-left {
  visibility: hidden;
  min-width: 200px;
  background-color: #fff;
  font-size: 0.9em;
  text-align: center;
  padding: 6px 6px;
  border-radius: 6px;
  border: 1px solid lightgray;
  line-height: 1.4;

  /* Position the tooltip text */
  position: absolute;
  top: -28px;
  right: 140%;
  z-index: 1;
}

.i-tooltip-left .i-tooltiptext-left::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent lightgray;
}

/* Show the tooltip text when you mouse over the tooltip container */
.i-tooltip-right:hover .i-tooltiptext-right, .i-tooltip-left:hover .i-tooltiptext-left {
  visibility: visible;
}

.i-tooltip-right .i-tooltiptext-right::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent lightgray transparent transparent;
}


/* Tooltip text */
.pedigree-handle .i-tooltiptext-pedigree {
  visibility: hidden;
  min-width: 110px;
  background-color: white;
  font-size: 0.7em;
  text-align: center;
  padding: 6px 6px;
  border-radius: 6px;
  border: 1px solid lightgray;
  line-height: 1;

  /* Position the tooltip text */
  position: absolute;
  top: 0px;
  left: 140%;

}

.i-tooltip-archive-pin {
  position: relative;
  display: inline-block;
}

.i-tooltip-archive-pin .i-tooltiptext-archive-pin {
  visibility: hidden;
  min-width: 80px;
  background-color: #fff;
  font-size: 0.9em;
  text-align: center;
  padding: 6px 6px;
  border-radius: 6px;
  border: 1px solid lightgray;
  line-height: 1.4;

  /* Position the tooltip text */
  position: absolute;
  top: -5px;
  left: 140%;
  z-index: 1;
}

.i-tooltip-archive-pin:hover .i-tooltiptext-archive-pin {
  visibility: visible;
}

.i-tooltip-archive-pin .i-tooltiptext-archive-pin::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent lightgray transparent transparent;
}

.i-tooltip-assign-donor {
  display: inline;
  position: 'absolute';
  right: 10;
}

.i-tooltip-assign-donor .i-tooltiptext-assign-donor {
  visibility: hidden;
  min-width: 140px;
  background-color: #fff;
  font-size: 0.9em;
  text-align: center;
  padding: 6px 6px;
  border-radius: 6px;
  border: 1px solid lightgray;
  line-height: 1.4;

  /* Position the tooltip text */
  position: absolute;
  top: -15px;
  right: 140%;
  z-index: 1;
}

.i-tooltip-assign-donor:hover .i-tooltiptext-assign-donor {
  visibility: visible;
}

.i-tooltip-assign-donor .i-tooltiptext-assign-donor::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent white;
}

/* Tooltip container */
.i-tooltip {
  position: relative;
  padding: 6px;
  float: right;
}

/* Tooltip text */
.i-tooltip .i-tooltiptext {
  visibility: hidden;
  min-width: 400px;
  background-color: #fff;
  font-size: 0.9em;
  text-align: center;
  padding: 6px 6px;
  border-radius: 6px;
  border: 1px solid lightgray;
  line-height: 1.4;

  /* Position the tooltip text */
  position: absolute;
  top: -15px;
  right: 30px;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.i-tooltip:hover .i-tooltiptext {
  visibility: visible;
}

.i-tooltip .i-tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent lightgray;
}

/* Tooltip container */
.i-tooltip-right-nccn {
  position: relative;
  display: inline-block;
  padding-left: 5px;
}

/* Tooltip text */
.i-tooltip-right-nccn .i-tooltiptext-right-nccn {
  visibility: hidden;
  /* Position the tooltip text */
  background-color: #fff;
  width: fit-content;

  position: fixed;
  top: -20px;
  z-index: 1;
  width: 750px;
  height: fit-content;
  top: 20%;

  margin-left: -775px;
  border-radius: 5px;
  line-height: 1.1em;
  font-size: 12px;
  padding: 5px;
  border: 2px solid lightgray;
}
.i-tooltiptext-right-nccn li {
  padding-bottom: 10px;
  color: black;
  font-weight: normal;
}
.i-tooltiptext-right-nccn p {
  color: black;
  text-align: center;
}
.i-tooltip-right-nccn .i-tooltiptext-right-nccn a:hover {
  color: red;
}
/* Show the tooltip text when you mouse over the tooltip container */
.i-tooltip-right-nccn:hover .i-tooltiptext-right-nccn {
  text-transform: none;
  visibility: visible;
}

.i-tooltip-right-nccn .i-tooltiptext-right-nccn::after {
  content: "";
  position: relative;
  margin-top: -5px;
}

.i-tooltip-column {
  position: relative;
  float: right;
}

/* Tooltip text */
.i-tooltip-query .i-tooltiptext-query {
  visibility: hidden;
  min-width: 100px;
  max-width: 420px;
  min-height: 20px;
  background-color: #fff;
  text-align: center;
  padding: 2px 2px;
  border-radius: 5px;
  right: 20px;
  bottom: 4px;
  border: 1px solid lightgray;

  /* Position the tooltip text */
  position: absolute;
}
.i-tooltip-query .i-tooltiptext-query::after {
  position: absolute;
  left: 100%; /* To the right of the tooltip */
  border-style: solid;
  border-color: transparent transparent transparent lightgray;
}
.i-tooltip-query:hover .i-tooltiptext-query {
  visibility: visible;
  text-transform: none;
}

.i-tooltip-column .i-tooltiptext-column {
  visibility: hidden;
  min-width: 200px;
  min-height: 20px;
  background-color: #fff;
  text-align: center;
  padding: 2px 2px;
  border-radius: 5px;
  border: 1px solid lightgray;

  /* Position the tooltip text */
  position: absolute;
  top: -17px;
  z-index: 1;
}
.i-tooltip-column .i-tooltiptext-column::after {
  position: absolute;
  left: 100%; /* To the right of the tooltip */
  border-style: solid;
  border-color: transparent transparent transparent lightgray;
}
.i-tooltip-column:hover .i-tooltiptext-column {
  visibility: visible;
  text-transform: none;
  cursor: pointer;
}

.patient-notif-accept-all {
  padding: 20px;
  padding-left: 15px;
  padding-top: 0px;
}

#copyPop {
  display: block;
  width: 150px !important;
}

#copyPop .popover-content {
  width: 150px !important;
}

/*
* CLINICIAN SURVEY CSS
*/
.survey-side-tab-margin {
  margin-top: 50px;
}

.survey-side-tab-header {
  color: #626262 !important;
  background: none !important;
}

.clinician-survey-tab {
  padding: 20px 30px;
}

.clinician-survey-category {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 15px 5px;
  padding: 15px 15px;
}

.clinician-survey-category h3.title {
  color: #6a358a;
}

.clinician-survey-label {
  line-height: 1.3;
}

.clinician-survey-toggle-label {
  margin-top: 10px;
  margin-bottom: 10px;
}
/************/

/*REACT-DATE-PICKER CSS, this date picker is used in IE11*/
.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__button {
  padding: 0px 3px !important;
}

.react-date-picker__inputGroup__input {
  padding: 0px 3px !important;
  margin: 0px 0px;
}

.react-date-picker__calendar {
  z-index: 2 !important;
}

input.react-date-picker_inputGroup__year {
  width: 35px !important;
}

input.react-date-picker_inputGroup__day {
  width: 15px !important;
}

input.react-date-picker_inputGroup__month {
  width: 9px !important;
}
/************/

/*Login CSS*/
a.reveal-password-eye {
  position: relative;
  left: 40%;
  bottom: 35px;
}
/************/

/*Offline Banner*/
.offline-banner {
  text-align: center;
  display: none;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

/************/
/*ArchiveTable*/
.archive-data-table {
  position: absolute;
  overflow: auto;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  color: #333333;
  font-size: 12px;
  padding: 10px;
  z-index: 9;
  min-width: 400px;
  min-height: 200px;
}

.archive-data-table table {
  width: -webkit-fill-available;
  border-spacing: 12px;
  margin: 3px;
  table-layout: fixed;
  border-collapse: collapse;
  overflow: scroll;
}

/*Resizeable*/

.archive-data-table .resizer-right {
  width: 7px;
  height: 100%;
  background: transparent;
  position: inherit;
  right: 0;
  bottom: 0;
  cursor: e-resize;
}

.archive-data-table .resizer-left {
  width: 7px;
  height: 100%;
  background: transparent;
  position: absolute;
  left: 0;
  bottom: 0;
  cursor: e-resize;
}

.archive-data-table .resizer-top {
  height: 7px;
  width: 100%;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  cursor: n-resize;
}

.archive-data-table .resizer-bottom {
  width: 100%;
  height: 7px;
  background: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: n-resize;
}

.archive-data-table .resizer-both {
  width: 5px;
  height: 7px;
  background: transparent;
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: nw-resize;
}

/*NOSELECT*/

.archive-data-table * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

#archive-table tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

#archive-headers {
  border-bottom: 2px solid #d4d4d4;
  font-size: 13px;
  font-weight: 600;
  overflow-wrap: break-word;
  page-break-inside: avoid;
  white-space: break-spaces;
  padding: 8px;
}

#archive-table-section {
  padding: 3px;
  table-layout: fixed;
  display: table-cell;
  min-width: 390px;
  height: 40px;
  align-items: center;
  vertical-align: bottom;
}

#archive-table-close {
  position: sticky;
  color: #d4d4d4;
  float: right;
  border: none;
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-size: 18px;
  z-index: 99;
}

#archive-table-header {
  font-size: 13px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  color: #6a358a;
}


#archive-table th {
  font-size: 13px;
  padding: 3px;
  font-weight: 600;
  color: #333333;
  table-layout: fixed;
  display: table-cell;
  min-width: 60px;
  text-align: center;
  line-height: 17px;
  vertical-align: bottom;
}

#archive-table tbody tr {
  font-size: 12px;
  color: #333333;
  border-bottom: 1px solid #d4d4d4;
  word-wrap: break-word;
  padding: 3px;
  text-align: center;
}

#archive-table td {
  word-wrap: break-word;
  font-family: "Montserrat", sans-serif;
}

/*LegendDOM*/
.legend-dom {
  position: absolute;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  padding: 5px;
  color: #333333;
  font-size: 12px;
  overflow-y: auto;
  z-index: 99;
}

.legend-dom-header {
  width: 100%;
}

#legend-notes {
  margin: 0px;
  font-size: 13px;
  min-height: 85px;
}

.legend-dom-row {
  display: flex;
}

.legend-dom-column-one {
  flex: 15%;
}

.legend-dom-column-two {
  flex: 85%;
}

.legend-dom .dismiss {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background: none;
  border-radius: 0;
  color: #fff;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  transition: all 0.3s;
  transition-property: all;
  display: block;
  border: none;
  box-shadow: none;
}

.legend-dom-color-square {
  height: 15px;
  width: 15px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
}

.legend-dom-inner-row {
  display: flex;
}

.legend-dom-inner-column-one {
  padding-top: 5px;
  margin-right: 5px;
}

.legend-dom-inner-column-two {
}

.legend-dom .legend-input {
  height: 50px;
}

.person-connector-node:after {
  content: "";
  position: absolute;
  left: -8px;
  top: -8px;
  width: 16px;
  height: 16px;
  background: transparent;
  z-index: 9999;
  /*Other styles*/
}

.react-date-picker__inputGroup__input {
  text-align: right !important;
  min-width: 13px !important;
}

.react-date-picker__wrapper {
  margin-left: -7px !important;
}

/************/

/*****ReleaseModal******/
.release-modal {
  background-color: white;
}

.release-modal ul {
  list-style: disc;
  padding-left: 40px;
  margin-bottom: 10px;
}
/************/

/*****FlexBoxModel******/
.flex-box-container {
  display: flex;
}

.flex-50 {
  flex: 50%;
}
/************/

.progeny-archive-search-btn {
  position: absolute;
  top: 5px;
  left: 145px;
}

.risk-cancel-button:hover,
.risk-cancel-button:active,
.risk-cancel-button:link,
.risk-cancel-button:focus,
.risk-cancel-button:visited {
  font-size: 11px;
  letter-spacing: 0.5px;
  padding: 10px 13px;
  color: #747474;
  background: transparent;
  border: 2px solid #e8e8e8;
  border-radius: 100px;
}

.vertical-line {
  border-left: 2px solid #eeeeee;
  height: 80%;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 5;
}

.save-report-dropdown {
  width: 275px;
}

.patient-pedigree-add-button {
  position: absolute;
  right: 50%;
  top: 85%;
  margin-right: 5px;
  z-index: 9999;
}

.patient-pedigree-edit-button {
  position: absolute;
  left: 50%;
  top: 85%;
  margin-left: 5px;
  z-index: 9999;
}

.add-filters-check {
  color: #6a358a
}

.add-filters-options {
  text-transform: none;
  padding-left: 10;
  float: left;
}

.advanced-filters-header {
  font-size: 24px;
  padding: 4px 15px 0;
  color: #6a358a;
  margin: 0;
  display: block;
  line-height: 1.8em;
  font-weight: 600;
  opacity: 1;
}

.progress-bar-container {
  display: 'flex';
  flex-direction: 'row';
  margin-right: '4%';
  align-items: 'center';
}

.custom-progress {
  width: '100px';
  height: '5px';
  margin-right: '5px';
  margin-top: '10px';
}

#consanguineous-tooltip {
  visibility: hidden;
  background: transparent;
  margin-left: 10px;
  padding: 10px;
  position: absolute;
  z-index: 9999;
  width: 130px;
  height: 100px;
  line-height: 1;
}

#re-assign-parents-tooltip {
  visibility: hidden;
  background: transparent;
  margin-left: 10px;
  padding: 10px;
  position: absolute;
  z-index: 9999;
  width: 130px;
  height: 100px;
  line-height: 1;
}

#donor-assign-tooltip {
  visibility: hidden;
  background: transparent;
  margin-left: 10px;
  padding: 10px;
  position: absolute;
  z-index: 9999;
  width: 130px;
  height: 100px;
  line-height: 1;
}


.react-flow__pane {
	cursor: default !important;
  line-height: 0px !important;
}

.table-container {
  height: 1140px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.header-wrapper {
  height: fit-content;
  overflow-x: auto;
  overflow-y: hidden;
  position: sticky;
}

.custom-scrollbar {
  height: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 20px;
}

.scrollable-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.header-table,
.body-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.table-container th, .table-container td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  width: fit-content;
}

.sticky-header th {
  vertical-align: bottom;
  border-bottom: 3px solid #ddd;
  line-height: 17px;
  position: sticky;
  top: 0;
}

.pw-terms-and-conditions{
  text-align: left;
}

.custom-data-label {
  margin-left: 0%;
}

.smart-wordwrap {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}

.select-container {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.select-container label {
  margin-right: 10px;
}

#select-clinician {
  width: 100%;
}

#patient-updates-tabs {
  display: flex;
  align-items: center;
}

/* Gene Modal  */
.gene-Modal{
  display: flex;
  left: 3px;
  bottom: 10px;
  align-items: flex-end;

}
.custom-react-select__value-container {
  padding: 0px 8px !important;
}

.custom-react-select__control{
  height: 34px;
}

.Dropdown-control{
  height: 34px;
}

.gene-panel-row{
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.first-gene-row{
  height: 67px;
}
.gene-row{
  height: 39px;
}

.add-gene{
  margin-top: 10px;
}
.inline-gene-buttons{
  align-items: center;
}


@keyframes purpleCycle {
  0% {
    color: #800080; /* Purple */
  }
  25% {
    color: #8a2be2; /* BlueViolet */
  }
  50% {
    color: #9932cc; /* DarkOrchid */
  }
  75% {
    color: #ba55d3; /* MediumOrchid */
  }
  100% {
    color: #dda0dd; /* Plum */
  }
}

.verifying-genes {
  margin-top: 50px;
  font-size: 32px;
  font-weight: bold;
  animation: purpleCycle 2s infinite;
}
.geneCheckBox{
  margin: 0px
}

.gene-modal-lab{
  padding-bottom: 20px
}
.remove-row-gene-modal{
  font-size: 1.5em;
  margin-top: 6px;
  margin-left: 20px;
}

.gene-modal-lab .react-select__menu {
  position: relative;
}

#gene-filter-result .Dropdown-placeholder {
  margin-top: 2px;
}

#gene-filter-select-gene {
  margin-top: 0px;
}

#gene-filter-select-gene .custom-react-select__single-value {
  margin-top: -4px;
  display: flex;
  align-items: center;
}

.modal-question-builder{
  width: 70%;
  position: relative;
  left: 15%;
}
.paper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 1px 1px 0 0;
  flex-grow: 9;
  border: #000000;
}


.paper {
  width: 100%; 
  max-width: 210mm; 
  height: auto; /* Keeps the aspect ratio */
  max-height: 297mm; /* A4 paper height */
  position: relative;
  border: 3px solid black; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}



.svg-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}


.pedigree-print-settings{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.print-options{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;  
  width: 40%;
  height: 100px;
}
.print-labels{
  font-family: 'Montserrat';
  font-size: 12px;
  margin-left: 5px;

}
